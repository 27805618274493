import { Outlet, Link } from 'react-router-dom';
import Logo from '../../imgs/TE-AMO-LOGO-2-(1).png';

const About = () => {
	 const showNav = () => {

      const nav = document.getElementById('nav');
      const menuBgTop = document.getElementById('menuBgTop');
      const menuBgBottom = document.getElementById('menuBgBottom');
      const menuBgCenter = document.getElementById('menuBgCenter');

      nav.style.top = '0%';
      menuBgTop.style.left = '-5000%';
      menuBgBottom.style.left = '-5000%';
      menuBgCenter.style.left = '-5000%';
  }

  const hideNav = () => {
      const nav = document.getElementById('nav');

      const menuBgTop = document.getElementById('menuBgTop');
      const menuBgBottom = document.getElementById('menuBgBottom');
      const menuBgCenter = document.getElementById('menuBgCenter');

      nav.style.top = '-110%';
      menuBgTop.style.left = '-0%';
      menuBgBottom.style.left = '-0%';
      menuBgCenter.style.left = '-0%';
  }

	return(
		<>
		<div className="about">
			<nav className='nav' id="nav">
		        <span onClick={()=>hideNav()}>X</span>
		        <ul>
		          <li><Link to="/">Home</Link></li>
		          <li><Link to="/about">About</Link></li>
		          <li><Link to="/repairs">Repairs</Link></li>
		          <li><Link to="/cctv">CCTV</Link></li>
		          <li><Link to="/pos">POS</Link></li>
		          <li><Link to="/network">Network</Link></li>
		          <li><Link to="/software">Software</Link></li>
		          <li><Link to="/InvertersAndAutomations">Inverters & Gate Automations</Link></li>
		          <li>Social</li>
		          <li>Contact</li>
		        </ul>
		    </nav>

			<div className='menuBg' id="menuBg" onClick={()=>showNav()}>
	            <div className='menuBgTop' id="menuBgTop">
	            </div>
	            <div className='menuBgCenter' id="menuBgCenter">
	            </div>
	            <div className='menuBgBottom' id="menuBgBottom">
	            </div>
	        </div>

	        <div className="logoPagesCover">
				<img
		              className='logoPages'
		              src={Logo}
		              alt="Next.js Logo"
		              width={390}
		              height={164}
		            />
	        </div>

	        <div className="container">
	        	<h1>Web Design/Development</h1>
	        	<p>Te-amo Business Solutions offers a variety of services related to website development, providing you with the option to get just what you need or a fully inclusive package if you happen to be starting from scratch. With over 7 years experience in the field of website development and online marketing, you may rest assured that your next project will be a long appreciated success.</p><br /><br />

	        	<h3>Our services related to this sector include:</h3>
	        	<ul class="list-group list-group-flush pb-5">
				  <li class="list-group-item list-group-item-success">Core website development</li>
				  <li class="list-group-item list-group-item-success">Website design</li>
				  <li class="list-group-item list-group-item-success">Graphic design (logo's, flyers, business cards, etc.)</li>
				  <li class="list-group-item list-group-item-success">Search Engine Optimization</li>
				  <li class="list-group-item list-group-item-success">Cyber security</li>
				  <li class="list-group-item list-group-item-success">And more (if you see dream it, we can do it!)</li>
				</ul>
	        </div>
		</div>

		<section className='topFooter'>
	        <div className="container">
	          <h1 className="text-center">Te-Amo Business Solutions</h1>

	          <div className="row">
	            <div className="col-md-4">
	              <p>2 Nelson Cresent Malvern, Queensburgh 4055</p>
	            </div>
	            <div className="col-md-4">
	              <p><b>Email</b>: help@te-amo.co.za</p>
	              <p><b>Email</b>: info@te-amo.co.za</p>
	            </div>
	            <div className="col-md-4">
	              <p><b>Phone</b>: 031 463 3124</p>
	              <p><b>24 HR Tech Support</b>: 076 113 8995</p>
	            </div>
	          </div>
	        </div>
	      </section>

	      <footer className='footer'>
	        <div className="container">
	            <p>© Copyright <b>Te-Amo Business Solutions 2024</b>. All Rights Reserved</p>
	        </div>
	      </footer>
	    </>
	)
}

export default About;