import { Outlet, Link } from 'react-router-dom';
import Logo from '../../imgs/TE-AMO-LOGO-2-(1).png';

import inv1 from '../../imgs/Inveters/unnamed.jpg';
import inv2 from '../../imgs/Inveters/unnamed (1).png';
import inv3 from '../../imgs/Inveters/unnamed (1).jpg';
import inv4 from '../../imgs/Inveters/unnamed (2).jpg';

const About = () => {
	 const showNav = () => {

      const nav = document.getElementById('nav');
      const menuBgTop = document.getElementById('menuBgTop');
      const menuBgBottom = document.getElementById('menuBgBottom');
      const menuBgCenter = document.getElementById('menuBgCenter');

      nav.style.top = '0%';
      menuBgTop.style.left = '-5000%';
      menuBgBottom.style.left = '-5000%';
      menuBgCenter.style.left = '-5000%';
  }

  const hideNav = () => {
      const nav = document.getElementById('nav');

      const menuBgTop = document.getElementById('menuBgTop');
      const menuBgBottom = document.getElementById('menuBgBottom');
      const menuBgCenter = document.getElementById('menuBgCenter');

      nav.style.top = '-110%';
      menuBgTop.style.left = '-0%';
      menuBgBottom.style.left = '-0%';
      menuBgCenter.style.left = '-0%';
  }

	return(
		<>
		<div className="about">
			<nav className='nav' id="nav">
		        <span onClick={()=>hideNav()}>X</span>
		        <ul>
		          <li><Link to="/">Home</Link></li>
		          <li><Link to="/about">About</Link></li>
		          <li><Link to="/repairs">Repairs</Link></li>
		          <li><Link to="/cctv">CCTV</Link></li>
		          <li><Link to="/pos">POS</Link></li>
		          <li><Link to="/network">Network</Link></li>
		          <li><Link to="/software">Software</Link></li>
		          <li><Link to="/InvertersAndAutomations">Inverters & Gate Automations</Link></li>
		          <li>Social</li>
		          <li>Contact</li>
		        </ul>
		    </nav>

			<div className='menuBg' id="menuBg" onClick={()=>showNav()}>
	            <div className='menuBgTop' id="menuBgTop">
	            </div>
	            <div className='menuBgCenter' id="menuBgCenter">
	            </div>
	            <div className='menuBgBottom' id="menuBgBottom">
	            </div>
	        </div>

	        <div className="logoPagesCover">
				<img
		              className='logoPages'
		              src={Logo}
		              alt="Next.js Logo"
		              width={390}
		              height={164}
		            />
	        </div>

	        <div className="container">
	        	<h1>Inverters & Gate Automations</h1>
	        	<p>TE-AMO BUSINESS SOLUTIONS are always looking for Innovative ways to assist our valued clientele and with the increase in Load-Shedding, we are always prepared to help our clients keep the power on. With a host of competitive Suppliers, you can rest assured that your needs are seen too.</p><br /><br />
	        		<img
	        		  className="inv-img"
		              src={inv1}
		              alt="Next.js Logo"
		            />

		            <img
		              className="inv-img"
		              src={inv2}
		              alt="Next.js Logo"
		            />
	        	<p>Keeping our Loved ones and valuables safe is our primary concern. Apartfrom CCTV. we also install and configure your Gate Automation system,  Alarms systems, including Beams are our specialty as well.</p><br /><br />
	        		<img
	        		  className="inv-img"
		              src={inv3}
		              alt="Next.js Logo"
		            />

		            <img
		              className="inv-img"
		              src={inv4}
		              alt="Next.js Logo"
		            />
	        </div>
		</div>

		<section className='topFooter'>
	        <div className="container">
	          <h1 className="text-center">Te-Amo Business Solutions</h1>

	          <div className="row">
	            <div className="col-md-4">
	              <p>2 Nelson Cresent Malvern, Queensburgh 4055</p>
	            </div>
	            <div className="col-md-4">
	              <p><b>Email</b>: help@te-amo.co.za</p>
	              <p><b>Email</b>: info@te-amo.co.za</p>
	            </div>
	            <div className="col-md-4">
	              <p><b>Phone</b>: 031 463 3124</p>
	              <p><b>24 HR Tech Support</b>: 076 113 8995</p>
	            </div>
	          </div>
	        </div>
	      </section>

	      <footer className='footer'>
	        <div className="container">
	            <p>© Copyright <b>Te-Amo Business Solutions 2024</b>. All Rights Reserved</p>
	        </div>
	      </footer>
	    </>
	)
}

export default About;