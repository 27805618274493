import { Outlet, Link } from 'react-router-dom';
import Logo from '../../imgs/TE-AMO-LOGO-2-(1).png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera, faVideo, faWifi, faMale, faCircle, faTv } from '@fortawesome/free-solid-svg-icons';

import cctv1 from '../../imgs/cctv/Untitled-1.png';
import cctv2 from '../../imgs/cctv/Untitled-2.png';
import cctv3 from '../../imgs/cctv/Untitled-3.png';
import cctv4 from '../../imgs/cctv/Untitled-4.png';
import cctv5 from '../../imgs/cctv/Untitled-6.png';
import cctv6 from '../../imgs/cctv/Untitled-5.png';
import cctv7 from '../../imgs/cctv/Untitled-7.png';
import cctv8 from '../../imgs/cctv/image008.png';
import cctv9 from '../../imgs/cctv/image010.jpg';


import cctv10 from '../../imgs/cctv/New folder/download.jpg';
import cctv11 from '../../imgs/cctv/New folder/gate_control_577px.jpg';
import cctv12 from '../../imgs/cctv/New folder/SysControl_header_hikvision-access-control2.jpg';
import cctv13 from '../../imgs/cctv/New folder/Untitled-8.png';
import cctv14 from '../../imgs/cctv/New folder/Untitled-9.png';
import cctv15 from '../../imgs/cctv/New folder/Untitled-10.png';
import cctv16 from '../../imgs/cctv/New folder/Untitled-11.png';

import {SlideshowLightbox} from 'lightbox.js-react';
import 'lightbox.js-react/dist/index.css';

const About = () => {
	 const showNav = () => {

      const nav = document.getElementById('nav');
      const menuBgTop = document.getElementById('menuBgTop');
      const menuBgBottom = document.getElementById('menuBgBottom');
      const menuBgCenter = document.getElementById('menuBgCenter');

      nav.style.top = '0%';
      menuBgTop.style.left = '-5000%';
      menuBgBottom.style.left = '-5000%';
      menuBgCenter.style.left = '-5000%';
  }

  const hideNav = () => {
      const nav = document.getElementById('nav');

      const menuBgTop = document.getElementById('menuBgTop');
      const menuBgBottom = document.getElementById('menuBgBottom');
      const menuBgCenter = document.getElementById('menuBgCenter');

      nav.style.top = '-110%';
      menuBgTop.style.left = '-0%';
      menuBgBottom.style.left = '-0%';
      menuBgCenter.style.left = '-0%';
  }

	return(
		<>
		<div className="about">
			<nav className='nav' id="nav">
		        <span onClick={()=>hideNav()}>X</span>
		        <ul>
		          <li><Link to="/">Home</Link></li>
		          <li><Link to="/about">About</Link></li>
		          <li><Link to="/repairs">Repairs</Link></li>
		          <li><Link to="/cctv">CCTV</Link></li>
		          <li><Link to="/pos">POS</Link></li>
		          <li><Link to="/network">Network</Link></li>
		          <li><Link to="/software">Software</Link></li>
		          <li><Link to="/InvertersAndAutomations">Inverters & Gate Automations</Link></li>
		          <li>Social</li>
		          <li>Contact</li>
		        </ul>
		    </nav>

			<div className='menuBg' id="menuBg" onClick={()=>showNav()}>
	            <div className='menuBgTop' id="menuBgTop">
	            </div>
	            <div className='menuBgCenter' id="menuBgCenter">
	            </div>
	            <div className='menuBgBottom' id="menuBgBottom">
	            </div>
	        </div>

	        <div className="logoPagesCover">
				<img
		              className='logoPages'
		              src={Logo}
		              alt="Next.js Logo"
		              width={390}
		              height={164}
		            />
	        </div>

	        <div className="container">
	        	<h1>CCTV INTEGRATION SERVICES</h1>
	        	<p>As an industry leader in the supply, design, installation and maintenance of CCTV solutions, Te-Amo Business Solutions has the expertise and experience in every aspect of security CCTV video to help you meet your CCTV security, safety and management requirements. Our CCTV cameras include:</p><br /><br />

	        	<div className="row">
	        		<div className="col-md-4">
			        	<div className="card-cctv">
			        		<FontAwesomeIcon icon={faCamera} className="cctv-icon" />
			        		<p>PTZ(PAN-TILT-ZOOM)</p>
			        	</div>
		        	</div>
		        	<div className="col-md-4">
			        	<div className="card-cctv">
			        		<FontAwesomeIcon icon={faWifi} className="cctv-icon" />
			        		<p>INFRA-RED(NIGHT VISION)</p>
			        	</div>
		        	</div>
		        	<div className="col-md-4">
			        	<div className="card-cctv">
			        		<FontAwesomeIcon icon={faMale} className="cctv-icon" />
			        		<p>MOTION DETECTOR</p>
			        	</div>
		        	</div>
		        	<div className="col-md-4">
			        	<div className="card-cctv">
			        		<FontAwesomeIcon icon={faVideo} className="cctv-icon" />
			        		<p>CCD (CHARGE COUPLE DEVICE)</p>
			        	</div>
		        	</div>
		        	<div className="col-md-4">
			        	<div className="card-cctv">
			        		<FontAwesomeIcon icon={faCircle} className="cctv-icon" />
			        		<p>DOMES</p>
			        	</div>
		        	</div>
		        	<div className="col-md-4">
			        	<div className="card-cctv">
			        		<FontAwesomeIcon icon={faTv} className="cctv-icon" />
			        		<p>REMOTE VIEWING</p>
			        	</div>
		        	</div>
	        	</div>

	        	<SlideshowLightbox className="container grid grid-cols-3 gap-2 mx-auto">
		            <img className="rounded w-small" src={cctv1} />
		            <img className="rounded w-small" src={cctv2} />  
		            <img className="rounded w-small" src={cctv3} />
		            <img className="rounded w-small" src={cctv4} />
		            <img className="rounded w-small" src={cctv5} />
		            <img className="rounded w-small" src={cctv6} />
		            <img className="rounded w-small" src={cctv7} />
		            <img className="rounded w-small" src={cctv8} />
		            <img className="rounded w-small" src={cctv9} />
		        </SlideshowLightbox>
		        <br />

		        <h1>Biometrics + Access Control</h1>
		        <p>For all your security requirements, we now offer additional systems for your home and business.</p>
		        <ul>
		        	<li>Face Recognition</li>
		        	<li>Fingerprint scanning</li>
		        	<li>Time and attandance</li>
		        	<li>Many more features</li>
		        </ul>

		        <SlideshowLightbox className="container grid grid-cols-3 gap-2 mx-auto">
		            <img className="rounded w-small" src={cctv10} />
		            <img className="rounded w-small" src={cctv11} />  
		            <img className="rounded w-small" src={cctv12} />
		            <img className="rounded w-small" src={cctv13} />
		            <img className="rounded w-small" src={cctv14} />
		            <img className="rounded w-small" src={cctv15} />
		            <img className="rounded w-small" src={cctv16} />
		        </SlideshowLightbox>
		        <br />
	        </div>
		</div>

		<section className='topFooter'>
	        <div className="container">
	          <h1 className="text-center">Te-Amo Business Solutions</h1>

	          <div className="row">
	            <div className="col-md-4">
	              <p>2 Nelson Cresent Malvern, Queensburgh 4055</p>
	            </div>
	            <div className="col-md-4">
	              <p><b>Email</b>: help@te-amo.co.za</p>
	              <p><b>Email</b>: info@te-amo.co.za</p>
	            </div>
	            <div className="col-md-4">
	              <p><b>Phone</b>: 031 463 3124</p>
	              <p><b>24 HR Tech Support</b>: 076 113 8995</p>
	            </div>
	          </div>
	        </div>
	      </section>

	      <footer className='footer'>
	        <div className="container">
	            <p>© Copyright <b>Te-Amo Business Solutions 2024</b>. All Rights Reserved</p>
	        </div>
	      </footer>
	    </>
	)
}

export default About;