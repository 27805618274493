import { useState, useEffect } from 'react';
import {Link} from 'react-router-dom';
import { Button, Element, Events, animateScroll as scroll, scrollSpy } from 'react-scroll';
import ParticleBackground from '../particleBackground';
import '../../App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faChevronDown, faCaretDown, faEnvelope, faTasks, faClock, faFile, faUsers, faBell, faEnvelopeOpenText, faTint, faMoneyBillAlt, faFastForward, faMapMarkerAlt, faPhone, faBars } from '@fortawesome/free-solid-svg-icons';

import Tilt from 'react-parallax-tilt';
//import stylesTilte from "@/styles/Tiltle.module.css";

import 'bootstrap/dist/css/bootstrap.min.css';

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import Logo from '../../imgs/TE-AMO-LOGO-2-(1).png';
import serv1 from '../../imgs/7mPfu2VpoR5eY6Jrx3bm69.png';
import serv2 from '../../imgs/Webp.net-resizeimage.jpg';
import serv3 from '../../imgs/image003.jpg';
import serv4 from '../../imgs/image004.jpg';
import serv5 from '../../imgs/image013 (1).jpg';

import serv6 from '../../imgs/image001_2.png';
import serv7 from '../../imgs/V1.png';
import serv8 from '../../imgs/c1.png';
import serv9 from '../../imgs/IMG-20230513-WA0010.jpg';
import serv10 from '../../imgs/Top-6-Software-Development-Methodologies.jpg';


import partner1 from '../../imgs/NewFolder/grey/1b41897a9b5507b479efe80492abedd5.png';
import partner2 from '../../imgs/NewFolder/grey/200px-HP_logo_2012.png';
import partner3 from '../../imgs/NewFolder/grey/1024px-Samsung_Logo.png';
import partner4 from '../../imgs/NewFolder/grey/1600px-Intel_logo_(2006).png';
import partner5 from '../../imgs/NewFolder/grey/Adata.png';
import partner6 from '../../imgs/NewFolder/grey/avghero-100832780-large.png';
import partner7 from '../../imgs/NewFolder/grey/Canon_logo-700x146.png';
import partner8 from '../../imgs/NewFolder/grey/Dell-Logo-700x394.png';
import partner9 from '../../imgs/NewFolder/grey/D-Link-symbol.png';
import partner10 from '../../imgs/NewFolder/grey/Hikvision-Logo.png';
import partner11 from '../../imgs/NewFolder/grey/Huawei-Logo-700x394.png';
import partner12 from '../../imgs/NewFolder/grey/infogremio-hilook-logo.png';
import partner13 from '../../imgs/NewFolder/grey/kisspng-kaspersky-lab-kaspersky-anti-virus-antivirus-softw-5adf3711b24c46.png';
import partner14 from '../../imgs/NewFolder/grey/kisspng-laptop-acer-aspire-computer-logo-lenovo-logo-5acb2ccf280490.png';
import partner15 from '../../imgs/NewFolder/grey/kisspng-logo-brand-lenovo-thinkpad-ideapad-lenovo-logo-vector-www-pixshark-com-images-gal-5b68c4f7459f03.png';
import partner16 from '../../imgs/NewFolder/grey/LG-logo-1080x608.png';
import partner17 from '../../imgs/NewFolder/grey/logo.png';
import partner18 from '../../imgs/NewFolder/grey/Logo_Netgear.png';
import partner19 from '../../imgs/NewFolder/grey/Microsoft-Logo-Transparent-Background.png';
import partner20 from '../../imgs/NewFolder/grey/SanDisk_logo.png';
import partner21 from '../../imgs/NewFolder/grey/Seagate-Logo.png';
import partner22 from '../../imgs/NewFolder/grey/TPLINK_Logo_2.png';
import partner23 from '../../imgs/NewFolder/grey/unnamed-(1).png';
import partner24 from '../../imgs/NewFolder/grey/unnamed-(2).png';
import partner25 from '../../imgs/NewFolder/grey/unnamed-(3).png';
import partner26 from '../../imgs/NewFolder/grey/unnamed-(4).png';
import partner27 from '../../imgs/NewFolder/grey/unnamed-(5).png';
import partner28 from '../../imgs/NewFolder/grey/unnamed-(6).png';
import partner29 from '../../imgs/NewFolder/grey/unnamed-(22).png';
import partner30 from '../../imgs/NewFolder/grey/unnamed-(45).png';
import partner31 from '../../imgs/NewFolder/grey/unnamed-(67).png';
import partner32 from '../../imgs/NewFolder/grey/unnamed.png';
import partner33 from '../../imgs/NewFolder/grey/unnamed1.png';

const Home = () => {
	const [scale, setScale] = useState(1.15);

  useEffect(() => {
    const handleScroll = () => {
      //setScrollPosition(window.scrollY);
      //console.log('Window scrolled to: ', window.scrollY);

      if(window.scrollY >= 600)
      {
        const downArrowHero = document.getElementById("downArrowHero");
        downArrowHero.style.position = "fixed";
        downArrowHero.style.left = "40px";
        downArrowHero.style.top = "80vh";
        downArrowHero.style.transform = "rotate(180deg)";
      }
      else
      {
        const downArrowHero = document.getElementById("downArrowHero");
        downArrowHero.style.position = "absolute";
        downArrowHero.style.left = "50%";
        downArrowHero.style.top = "90vh";
        downArrowHero.style.transform = "rotate(0deg)";
      }
    };

    window.addEventListener('scroll', handleScroll);
    
    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const showNav = () => {

      const nav = document.getElementById('nav');
      const menuBgTop = document.getElementById('menuBgTop');
      const menuBgBottom = document.getElementById('menuBgBottom');
      const menuBgCenter = document.getElementById('menuBgCenter');

      nav.style.top = '0%';
      menuBgTop.style.left = '-5000%';
      menuBgBottom.style.left = '-5000%';
      menuBgCenter.style.left = '-5000%';
  }

  const hideNav = () => {
      const nav = document.getElementById('nav');

      const menuBgTop = document.getElementById('menuBgTop');
      const menuBgBottom = document.getElementById('menuBgBottom');
      const menuBgCenter = document.getElementById('menuBgCenter');

      nav.style.top = '-110%';
      menuBgTop.style.left = '-0%';
      menuBgBottom.style.left = '-0%';
      menuBgCenter.style.left = '-0%';
  }

  const moveBottomLeft = () => {
      const downArrowHero = document.getElementById("downArrowHero");
      downArrowHero.style.position = "fixed";
      downArrowHero.style.left = "40px";
      downArrowHero.style.top = "80vh";
      //transform: rotate(-180deg);
      if(window.scrollY < 600)
      {
        window.scrollTo(0, 600);
        downArrowHero.style.transform = "rotate(-180deg)";
        setTimeout(()=>{
          console.log('Window scrolled to: ', window.scrollY);
        }, 500);
      }
      else if(window.scrollY >= 600)
      {
        window.scrollTo(0, 630);
        downArrowHero.style.transform = "rotate(-180deg)";
        setTimeout(()=>{
          console.log('Window scrolled to: ', window.scrollY);
        }, 500);
      }
	}
	return(
		<div className='body'>

	      <nav className='nav' id="nav">
	        <span onClick={()=>hideNav()}>X</span>
	        <ul>
	          <li><Link to="/">Home</Link></li>
	          <li><Link to="/about">About</Link></li>
	          <li><Link to="/repairs">Repairs</Link></li>
	          <li><Link to="/cctv">CCTV</Link></li>
	          <li><Link to="/pos">POS</Link></li>
	          <li><Link to="/network">Network</Link></li>
	          <li><Link to="/software">Software</Link></li>
	          <li><Link to="/InvertersAndAutomations">Inverters & Gate Automations</Link></li>
	          <li>Social</li>
	          <li>Contact</li>
	        </ul>
	      </nav>

	      <header className='header' id="parent-div">
	        <div className='headerTopLayer'>
	          <div className='menuBg' id="menuBg" onClick={()=>showNav()}>
	            <div className='menuBgTop' id="menuBgTop">
	            </div>
	            <div className='menuBgCenter' id="menuBgCenter">
	            </div>
	            <div className='menuBgBottom' id="menuBgBottom">
	            </div>
	          </div>
	          <ParticleBackground />
	          {/*<ParticleBackground />*/}
	          <div className='imageWrapper'>
	            <img
	              className='logoCover'
	              src={Logo}
	              alt="Next.js Logo"
	              width={390}
	              height={164}
	            />
	          </div>
	          <h1>We pride ourselves in providing cost-effective and efficient solutions to our valued clientele</h1>
	          {/*<FontAwesomeIcon icon={faCaretDown} />*/}
	          <a href="#" className="down-arrow-link">
	            <FontAwesomeIcon icon={faChevronDown} className="downArrowHero" id="downArrowHero" onClick={()=>moveBottomLeft()} />
	          </a>
	        </div>
	        {/*<div className={styles.bottomArrow}>
	          .
	        </div>*/}

	      </header>

	      <section className="secOneHalf" id="sechlf1">
	        <div className="container">
	          <Tilt glareEnable={true} glareMaxOpacity={0.1} glareColor="lightgreen" glarePosition="all" glareBorderRadius="20px" tiltMaxAngleX={3} tiltMaxAngleY={3} scale={1.0}>
	            <Link to="/repairs">
		            <div className='servicCard'>
		                <div className="row">
		                  <div className='col-md-7'>
		                    <img
		                      className="card-img"
		                      src={serv1}
		                      alt="Next.js Logo"
		                      layout="responsive"
		                      priority
		                    />
		                  </div>
		                  <div className='col-md-5'>
		                    <h1 className="text-light" style={{textAlign: 'center'}}>PC Repairs</h1>
		                    <p>Here at Te-Amo Business Solutions we know that computers in the work place are a crucial tool used every day when running your business, with that said; when they have a fault it is crucial that the problem be fixed quickly and efficiently. This is where we can help. We can come out to you within 24 hours and repair the problem on site! No more worries about transporting your equipment to faraway places, and then having to come back to get them again! Alternatively, if you would prefer an engineer can visit out of office hours (when there is less disruption to your working day). We also offer weekend callouts with no extra charge. (Please be advised that callouts after 6pm will incur an extra charge).:</p>
		                     <FontAwesomeIcon icon={faChevronRight} className="right-arrow" />
		                  </div>
		                </div>
		            </div>
	            </Link>
	          </Tilt>
	        </div>
	      </section>

	      <section className="secOneHalf">
	        <div className="container">
	          <Tilt glareEnable={true} glareMaxOpacity={0.1} glareColor="lightgreen" glarePosition="all" glareBorderRadius="20px" tiltMaxAngleX={3} tiltMaxAngleY={3} >
	            <Link to="/cctv">
		            <div className='servicCard'>
		                <div className="row">
		                  <div className='col-md-5'>
		                    <h1 className="text-light" style={{textAlign: 'center'}}>CCTV Integration</h1>
		                    <p>As an industry leader in the supply, design, installation and maintenance of CCTV solutions, Te-Amo Business Solutions has the expertise and experience in every aspect of security CCTV video to help you meet your CCTV security, safety and management requirements. Our CCTV cameras include:</p>
		                    <FontAwesomeIcon icon={faChevronLeft} className="left-arrow" style={{marginTop: '6em'}} />
		                  </div>
		                  <div className='col-md-7'>
		                    <img
		                      className="card-img"
		                      src={serv2}
		                      alt="Next.js Logo"
		                      layout="responsive"
		                      priority
		                    />
		                  </div>
		                </div>
		            </div>
	            </Link>
	          </Tilt>
	        </div>
	      </section>

	      <section className="secOneHalf">
	        <div className="container">
	          <Tilt glareEnable={true} glareMaxOpacity={0.1} glareColor="lightgreen" glarePosition="all" glareBorderRadius="20px" tiltMaxAngleX={3} tiltMaxAngleY={3} scale={1.0}>
	            <Link to="/pos">
		            <div className='servicCard'>
		                <div className="row">
		                  <div className='col-md-7'>
		                    <img
		                      className="card-img"
		                      src={serv3}
		                      alt="Next.js Logo"
		                      layout="responsive"
		                      priority
		                    />
		                  </div>
		                  <div className='col-md-5'>
		                    <h1 className="text-light" style={{textAlign: 'center'}}>POINT OF SALE</h1>
		                     <p>Point of Sale (POS) software, also known as electronic Point of Sale (ePOS), is an essential application for retail or hospitality businesses because it manages checkout operations and is what the customer sees when they make a transaction.:</p>
		                     <FontAwesomeIcon icon={faChevronRight} className="right-arrow" style={{marginTop: '6em'}} />
		                  </div>
		                </div>
		            </div>
	            </Link>
	          </Tilt>
	        </div>
	      </section>


	      <section className="secOneHalf">
	        <div className="container">
	          <Tilt glareEnable={true} glareMaxOpacity={0.1} glareColor="lightgreen" glarePosition="all" glareBorderRadius="20px" tiltMaxAngleX={3} tiltMaxAngleY={3} >
	            <Link to="/network">
		            <div className='servicCard'>
		                <div className="row">
		                  <div className='col-md-5'>
		                    <h1 className="text-light" style={{textAlign: 'center'}}>NETWORKING AND INFRASTRUCTURE</h1>
		                    <p>Your network is the cornerstone of your business, connecting employees across sites and countries. As networks grow the challenges grow increasingly more complex, increasing the number of users and the addition of new services eats up more of your precious bandwidth. We ensure your network is capable of meeting your business objectives.</p>
		                    <FontAwesomeIcon icon={faChevronLeft} className="left-arrow" style={{marginTop: '3em'}} />
		                  </div>
		                  <div className='col-md-7'>
		                    <img
		                      className="card-img"
		                      src={serv4}
		                      alt="Next.js Logo"
		                      layout="responsive"
		                      priority
		                    />
		                  </div>
		                </div>
		            </div>
	            </Link>
	          </Tilt>
	        </div>
	      </section>

	      <section className="secOneHalf">
	        <div className="container">
	          <Tilt glareEnable={true} glareMaxOpacity={0.1} glareColor="lightgreen" glarePosition="all" glareBorderRadius="20px" tiltMaxAngleX={3} tiltMaxAngleY={3} scale={1.0}>
	            <Link to="/software">
		            <div className='servicCard'>
		                <div className="row">
		                  <div className='col-md-7'>
		                    <img
		                      className="card-img"
		                      src={serv10}
		                      alt="Next.js Logo"
		                      layout="responsive"
		                      priority
		                    />
		                  </div>
		                  <div className='col-md-5'>
		                    <h1 className="text-light" style={{textAlign: 'center'}}>SOFTWARE DEVELOPMENT</h1>
		                     <p>Te-amo Business Solutions offers a variety of services related to website development, providing you with the option to get just what you need or a fully inclusive package if you happen to be starting from scratch. With over 7 years experience in the field of website development and online marketing, you may rest assured that your next project will be a long appreciated success.</p>
		                     <FontAwesomeIcon icon={faChevronRight} className="right-arrow" style={{marginTop: '3em'}} />
		                  </div>
		                </div>
		            </div>
	            </Link>
	          </Tilt>
	        </div>
	      </section>

	      <section className="secOneHalf">
	        <div className="container">
	          <Tilt glareEnable={true} glareMaxOpacity={0.1} glareColor="lightgreen" glarePosition="all" glareBorderRadius="20px" tiltMaxAngleX={3} tiltMaxAngleY={3} >
	            <Link to="/InvertersAndAutomations">
		            <div className='servicCard'>
		                <div className="row">
		                  <div className='col-md-5'>
		                    <h1 className="text-light" style={{textAlign: 'center'}}>Inverters & Gate Automations</h1>
		                    <p>Your network is the cornerstone of your business, connecting employees across sites and countries. As networks grow the challenges grow increasingly more complex, increasing the number of users and the addition of new services eats up more of your precious bandwidth. We ensure your network is capable of meeting your business objectives.</p>
		                    <FontAwesomeIcon icon={faChevronLeft} className="left-arrow" style={{marginTop: '3em'}} />
		                  </div>
		                  <div className='col-md-7'>
		                    <img
		                      className="card-img"
		                      src={serv6}
		                      alt="Next.js Logo"
		                      layout="responsive"
		                      priority
		                    />
		                  </div>
		                </div>
		            </div>
	            </Link>
	          </Tilt>
	        </div>
	      </section>

	      <section className="secOneHalf">
	        <div className="container">
	          <Tilt glareEnable={true} glareMaxOpacity={0.1} glareColor="lightgreen" glarePosition="all" glareBorderRadius="20px" tiltMaxAngleX={3} tiltMaxAngleY={3} scale={1.0}>
	            <Link to="/vox">
		            <div className='servicCard'>
		                <div className="row">
		                  <div className='col-md-7'>
		                    <img
		                      className="card-img"
		                      src={serv7}
		                      alt="Next.js Logo"
		                      layout="responsive"
		                      priority
		                    />
		                  </div>
		                  <div className='col-md-5'>
		                    <h1 className="text-light" style={{textAlign: 'center'}}>FIBER/ADSL</h1>
		                     <p>Te-Amo Business Solutions is now a Registered Business Partner with Vox Telecommunications. Visit us online by clicking on the link below for a package that suits your needs.</p>
		                     <FontAwesomeIcon icon={faChevronRight} className="right-arrow" style={{marginTop: '7em'}} />
		                  </div>
		                </div>
		            </div>
	            </Link>
	          </Tilt>
	        </div>
	      </section>

	      <section className="secOneHalf">
	        <div className="container">
	          <Tilt glareEnable={true} glareMaxOpacity={0.1} glareColor="lightgreen" glarePosition="all" glareBorderRadius="20px" tiltMaxAngleX={3} tiltMaxAngleY={3} >
	            <Link to="/shop">
		            <div className='servicCard'>
		                <div className="row">
		                  <div className='col-md-5'>
		                    <h1 className="text-light" style={{textAlign: 'center'}}>RETAIL STORE</h1>
		                    <p></p>
		                    <FontAwesomeIcon icon={faChevronLeft} className="left-arrow" style={{marginTop: '10em'}} />
		                  </div>
		                  <div className='col-md-7'>
		                    <img
		                      className="card-img"
		                      src={serv9}
		                      alt="Next.js Logo"
		                      layout="responsive"
		                      priority
		                    />
		                  </div>
		                </div>
		            </div>
	            </Link>
	          </Tilt>
	        </div>
	      </section>

	      <section className="secOneHalf">
	        <div className="container">
	          <Tilt glareEnable={true} glareMaxOpacity={0.1} glareColor="lightgreen" glarePosition="all" glareBorderRadius="20px" tiltMaxAngleX={3} tiltMaxAngleY={3} scale={1.0}>
	            <Link to="/catalogue">
		            <div className='servicCard'>
		                <div className="row">
		                  <div className='col-md-7'>
		                    <img
		                      className="card-img"
		                      src={serv8}
		                      alt="Next.js Logo"
		                      layout="responsive"
		                      priority
		                    />
		                  </div>
		                  <div className='col-md-5'>
		                    <h1 className="text-light" style={{textAlign: 'center'}}>CATALOGUE</h1>
		                     <p></p>
		                     <FontAwesomeIcon icon={faChevronRight} className="right-arrow" style={{marginTop: '10em'}} />
		                  </div>
		                </div>
		            </div>
	            </Link>
	          </Tilt>
	        </div>
	      </section>

	      <section className='sec2'>

	        <h1 className="text-light text-center">Our Partners</h1>

	        <Carousel
	         additionalTransfrom={0}
	  arrows
	  autoPlay
	  autoPlaySpeed={1000}
	  centerMode={false}
	  className=""
	  containerClass="container-with-dots"
	  dotListClass=""
	  draggable
	  focusOnSelect={false}
	  infinite
	  itemClass=""
	  keyBoardControl
	  minimumTouchDrag={80}
	  pauseOnHover
	  renderArrowsWhenDisabled={false}
	  renderButtonGroupOutside={false}
	  renderDotsOutside={false}
	  responsive={{
	    desktop: {
	      breakpoint: {
	        max: 3000,
	        min: 1024
	      },
	      items: 3,
	      partialVisibilityGutter: 40
	    },
	    mobile: {
	      breakpoint: {
	        max: 464,
	        min: 0
	      },
	      items: 1,
	      partialVisibilityGutter: 30
	    },
	    tablet: {
	      breakpoint: {
	        max: 1024,
	        min: 464
	      },
	      items: 2,
	      partialVisibilityGutter: 30
	    }
	  }}
	  rewind={false}
	  rewindWithAnimation={false}
	  rtl={false}
	  shouldResetAutoplay
	  showDots={false}
	  sliderClass=""
	  slidesToSlide={2}
	  swipeable
	        >
	          <img
	            src={partner1}
	            alt="Next.js Logo"
	            width={90}
	            height={54}
	            priority
	          />
	          <img
	            src={partner2}
	            alt="Next.js Logo"
	            width={90}
	            height={54}
	            priority
	          />
	          <img
	            src={partner3}
	            alt="Next.js Logo"
	            width={90}
	            height={54}
	            priority
	          />
	          <img
	            src={partner4}
	            alt="Next.js Logo"
	            width={90}
	            height={54}
	            priority
	          />
	          <img
	            src={partner5}
	            alt="Next.js Logo"
	            width={90}
	            height={54}
	            priority
	          />
	          <img
	            src={partner6}
	            alt="Next.js Logo"
	            width={90}
	            height={54}
	            priority
	          />
	          <img
	            src={partner7}
	            alt="Next.js Logo"
	            width={90}
	            height={54}
	            priority
	          />
	          <img
	            src={partner8}
	            alt="Next.js Logo"
	            width={90}
	            height={54}
	            priority
	          />



	          <img
	            src={partner9}
	            alt="Next.js Logo"
	            width={90}
	            height={54}
	            priority
	          />
	          <img
	            src={partner10}
	            alt="Next.js Logo"
	            width={90}
	            height={54}
	            priority
	          />
	          <img
	            src={partner11}
	            alt="Next.js Logo"
	            width={90}
	            height={54}
	            priority
	          />
	          <img
	            src={partner12}
	            alt="Next.js Logo"
	            width={90}
	            height={54}
	            priority
	          />
	          <img
	            src={partner13}
	            alt="Next.js Logo"
	            width={90}
	            height={54}
	            priority
	          />
	          <img
	            src={partner14}
	            alt="Next.js Logo"
	            width={90}
	            height={54}
	            priority
	          />
	          <img
	            src={partner15}
	            alt="Next.js Logo"
	            width={90}
	            height={54}
	            priority
	          />
	          <img
	            src={partner16}
	            alt="Next.js Logo"
	            width={90}
	            height={54}
	            priority
	          />
	          <img
	            src={partner17}
	            alt="Next.js Logo"
	            width={90}
	            height={54}
	            priority
	          />
	          <img
	            src={partner18}
	            alt="Next.js Logo"
	            width={90}
	            height={54}
	            priority
	          />
	          <img
	            src={partner19}
	            alt="Next.js Logo"
	            width={90}
	            height={54}
	            priority
	          />
	          <img
	            src={partner20}
	            alt="Next.js Logo"
	            width={90}
	            height={54}
	            priority
	          />




	          <img
	            src={partner21}
	            alt="Next.js Logo"
	            width={90}
	            height={54}
	            priority
	          />
	          <img
	            src={partner22}
	            alt="Next.js Logo"
	            width={90}
	            height={54}
	            priority
	          />
	          <img
	            src={partner23}
	            alt="Next.js Logo"
	            width={90}
	            height={54}
	            priority
	          />
	          <img
	            src={partner24}
	            alt="Next.js Logo"
	            width={90}
	            height={54}
	            priority
	          />
	          <img
	            src={partner25}
	            alt="Next.js Logo"
	            width={90}
	            height={54}
	            priority
	          />
	          <img
	            src={partner26}
	            alt="Next.js Logo"
	            width={90}
	            height={54}
	            priority
	          />
	          <img
	            src={partner27}
	            alt="Next.js Logo"
	            width={90}
	            height={54}
	            priority
	          />
	          <img
	            src={partner28}
	            alt="Next.js Logo"
	            width={90}
	            height={54}
	            priority
	          />
	          <img
	            src={partner29}
	            alt="Next.js Logo"
	            width={90}
	            height={54}
	            priority
	          />
	          <img
	            src={partner30}
	            alt="Next.js Logo"
	            width={90}
	            height={54}
	            priority
	          />
	          <img
	            src={partner31}
	            alt="Next.js Logo"
	            width={90}
	            height={54}
	            priority
	          />
	          <img
	            src={partner32}
	            alt="Next.js Logo"
	            width={90}
	            height={54}
	            priority
	          />



	          <img
	            src={partner33}
	            alt="Next.js Logo"
	            width={90}
	            height={54}
	            priority
	          />
	        </Carousel>
	        <br /><br /><br /><br /><br />
	      </section>

	      
	      <section className='topFooter'>
	        <div className="container">
	          <h1 className="text-center">Te-Amo Business Solutions</h1>

	          <div className="row">
	            <div className="col-md-4">
	              <p>2 Nelson Cresent Malvern, Queensburgh 4055</p>
	            </div>
	            <div className="col-md-4">
	              <p><b>Email</b>: help@te-amo.co.za</p>
	              <p><b>Email</b>: info@te-amo.co.za</p>
	            </div>
	            <div className="col-md-4">
	              <p><b>Phone</b>: 031 463 3124</p>
	              <p><b>24 HR Tech Support</b>: 076 113 8995</p>
	            </div>
	          </div>
	        </div>
	      </section>

	      <footer className='footer'>
	        <div className="container">
	            <p>© Copyright <b>Te-Amo Business Solutions 2024</b>. All Rights Reserved</p>
	        </div>
	      </footer>
      
    </div>
	)
}

export default Home